import {Directive, OnDestroy} from '@angular/core';
import * as _ from "lodash";
import { Subscription } from 'rxjs';

@Directive()
export abstract class AbstractComponentWithSubscriptions implements OnDestroy {
  /**
   * @description
   * Should not be manipulated directly
   *
   * Adding or removing a subscription should be done inside this class
   */
  public subscriptions: Subscription[] = [];
  /**
   * @description
   * Add the given subscription inside the list of subscriptions
   */
  public set registerSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }
  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}
