import { Operator } from './operator.model';
import { Notification } from './notification.model';
export class Inspector{
    id : number;
    adresse : string="";
    email : string="";
    code : string="";
    name: string="";
    tel: string="";
    status: String="";
    operator: Operator;
    notification: Notification;
    username: string;
    password: string;
    pas: string;
    role: String [];  
    imageModel:any;
 

}