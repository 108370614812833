import { Injectable} from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Review } from '../model/review.model';

import { environment } from '../../environments/environment';

@Injectable()

export class ReviewService {
    constructor(public http:HttpClient){}

  url = environment.baseUrl;

  getsReviews() {
       return this.http.get(this.url+'/review')
      .pipe(map(data => data))
      
    }


  getReview(id:number) {
      return this.http.get(this.url+'/review/'+id)
      .pipe(map(data => data))
      
    }
 
  saveReview(review) {
 
    return this.http.post<Review>(this.url+'/review',review)
    .pipe(map(data => data))
  }


  

  updateReview(review: Review) {
 
    return this.http.put<Review>(this.url+'/review/'+review.id,review)
    .pipe(map(data => data))
  }


  deleteReview(id: number ) {
 
    return this.http.delete(this.url+'/image/deleteBlob/'+id)
    .pipe(map(data => data))
  }

 

} 