import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Laboratory } from '../model/Laboratory.model';
import { environment } from '../../environments/environment';

@Injectable()

export class laboratoryService {
    constructor(public http:HttpClient){}

    url = environment.baseUrl;  
    //url = environment.baseUrl;

  gets() {
       return this.http.get(this.url+'/laboratory')
      .pipe(map(data => data))
      
    }

  get(id:number) {
      return this.http.get(this.url+'/laboratory/'+id)
      .pipe(map(data => data))
      
    }
 
  save(sv) {
 
    return this.http.post<Laboratory>(this.url+'/laboratory',sv)
    .pipe(map(data => data))
  }

  

  update(activity: Laboratory) {
 
    return this.http.put<Laboratory>(this.url+'/laboratory/'+activity.id,activity)
    .pipe(map(data => data))
  }


  delete(id: number ) {
 
    return this.http.delete(this.url+'/laboratory/'+id)
    .pipe(map(data => data))
  }

 

} 